.clickable {
  cursor: pointer !important;
}

.form-group > label {
  font-weight: bold !important;
}

.form-control,
.btn,
.form-control:focus {
  box-shadow: none !important;
}

.table td.fit,
.table th.fit {
  white-space: nowrap;
  width: 1%;
}

.table-responsive {
  min-height: 300px;
}

.input-group + .invalid-feedback {
  display: block;
}

/* react-datepicker + bootstrap compatibility */
.react-datepicker-wrapper {
  display: block;
}
.react-datepicker-wrapper + .invalid-feedback {
  display: block;
}

.react-datepicker-popper {
  z-index: 9;
}

.text-jumbo {
  font-size: 35px;
}
